import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Item from "../components/Item"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import "../utils/image"

export const query = graphql`
  query {
    faisselle: file(relativePath: { eq: "fromages/faisselle.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFraisNature: file(
      relativePath: { eq: "fromages/rondFraisNature.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFraisHerbes: file(
      relativePath: { eq: "fromages/rondFraisHerbes.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFraisAilHerbes: file(
      relativePath: { eq: "fromages/rondFraisAilHerbes.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFraisPoivre: file(
      relativePath: { eq: "fromages/rondFraisPoivre.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFrais4Jours: file(
      relativePath: { eq: "fromages/rondFrais4Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bucheFraiche4Jours: file(relativePath: { eq: "fromages/notFound.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondFrais8Jours: file(
      relativePath: { eq: "fromages/rondFrais8Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bucheFraiche8Jours: file(
      relativePath: { eq: "fromages/bucheFraiche8Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondCendre8Jours: file(
      relativePath: { eq: "fromages/rondCendre8Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bucheCendree8Jours: file(relativePath: { eq: "fromages/notFound.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondDemiFrais15Jours: file(
      relativePath: { eq: "fromages/rondDemiFrais15Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bucheDemiFraiche15Jours: file(
      relativePath: { eq: "fromages/bucheDemiFraiche15Jours.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondDemiSec: file(relativePath: { eq: "fromages/notFound.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rondSec: file(relativePath: { eq: "fromages/rondSec.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tomme: file(relativePath: { eq: "fromages/tomme.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Fromages = () => {
  const description = `Il y a différents types de fromages`

  return (
    <Layout>
      <SEO title="Les Fromages" description={description} />
      <div style={{ marginBottom: "1.8rem" }}>
        <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
          Les Fromages
        </h1>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
      <StaticQuery
        query={query}
        render={({
          faisselle,
          rondFraisNature,
          rondFraisHerbes,
          rondFraisAilHerbes,
          rondFraisPoivre,
          rondFrais4Jours,
          bucheFraiche4Jours,
          rondFrais8Jours,
          bucheFraiche8Jours,
          rondCendre8Jours,
          bucheCendree8Jours,
          rondDemiFrais15Jours,
          bucheDemiFraiche15Jours,
          rondDemiSec,
          rondSec,
          tomme,
        }) => (
          <>
            <Item
              image={faisselle}
              title="Faisselle"
              text={
                <p>
                  C’est un fromage frais qui a perdu très peu de lactosérum. Il
                  peut être battu et consommé aussi bien nature que sucré pour
                  le dessert.
                </p>
              }
              left
            ></Item>
            <Item
              image={rondFraisNature}
              title="Fromage frais nature"
              text={
                <p>
                  Le fromage frais nature est la fromage à la base de tous les
                  autres. Fraîchement salé, il a un goût très doux, légèrement
                  acidulé et lacté.
                </p>
              }
              left
            ></Item>
            <Item
              image={rondFraisHerbes}
              title="Fromage frais aromatisé aux herbes de Provence"
              text={
                <p>
                  Le fromage frais nature peut être aromatisé aux herbes de
                  provence, un mélange composé de thym, de romarin, de
                  sarriette, d'origan et d’hysope.
                </p>
              }
              left
            ></Item>
            <Item
              image={rondFraisAilHerbes}
              title="Fromage frais aromatisé à l’ail et aux herbes de Provence"
              text={
                <p>
                  Le fromage frais nature peut être aromatisé aux herbes de
                  provence, un mélange composé de d’ail, de thym, de romarin, de
                  sarriette, d’origan et d’hysope.
                </p>
              }
              left
            ></Item>
            <Item
              image={rondFraisPoivre}
              title="Fromage frais au poivre"
              text={
                <p>
                  Le fromage frais nature peut être aromatisé au poivre noir
                  concassé.
                </p>
              }
              left
            ></Item>
            <Item
              image={rondFrais4Jours}
              title="Fromage demi frais rond affiné de 4-5 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={bucheFraiche4Jours}
              title="Fromage demi frais bûchette affiné de 4-5 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={rondFrais8Jours}
              title="Fromage demi frais rond affiné de 8-10 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={bucheFraiche8Jours}
              title="Fromage demi frais bûchette affiné de 8-10 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={rondCendre8Jours}
              title="Fromage demi frais cendré rond affiné de 8-10 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={bucheCendree8Jours}
              title="Fromage demi frais cendré bûchette affiné de 8-10 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={rondDemiFrais15Jours}
              title="Fromage demi frais rond affiné de 15 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={bucheDemiFraiche15Jours}
              title="Fromage demi frais bûchette affiné de 15 jours"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={rondDemiSec}
              title="Fromage demi sec rond affiné de 3 semaines"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={rondSec}
              title="Fromages secs ronds affinés de 1 mois et plus"
              text={<p></p>}
              left
            ></Item>
            <Item
              image={tomme}
              title="Tomme affinée de 3 semaines minimum"
              text={<p></p>}
              left
            ></Item>
          </>
        )}
      />
    </Layout>
  )
}

export default Fromages
